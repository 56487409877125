:root {
  // Colors
  --white: #fff;
  --primary: #003362;
  --secondary: #ef4135;
  --tertiary: #176e7e;
  --focus: #f6d046;
  --primary-light: #0055a4;
  --primary-dark: #08103f;
  --borderColor: #dddddd;
  --paleBlue: #003362;
  --darkGrey: #282828;
  --grey: #61616c;
  --mediumGrey: #c4c4c4;
  --lightGrey: #f2f2f2;
  --lightRed: #ef5f6c;
  --warning: rgb(252, 244, 232);
  --tagRed: #fce6d9;
  --tagBlue: #c2ebf4;
  --tagGreen: #c8f9d8;

  ///--------------------------------------------------------///

  // font-size

  --text-2xs: 0.75rem; //9px
  --text-xs: 0.85rem; //14px
  --text-base: 1rem; //16px
  --text-lg: 1.1rem; //18px
  --text-xl: 1.175rem; //20px
  --text-2xl: 1.25rem; //24px
  --text-3xl: 1.5rem; //30px
  --text-4xl: 1.875rem; //36px
  --text-5xl: 2.25rem; //48px
  --text-6xl: 3rem; //60px
  --text-7xl: 3.75rem; //72px
  --text-8xl: 4.5rem; //96px
  --text-9xl: 6rem; //128px

  ///--------------------------------------------------------///

  // font-size
  --spacing-3xs: 0.275rem; //5px
  --spacing-2xs: 0.475rem; //9px
  --spacing-xs: 0.675rem; //15px
  --spacing-base: 0.8rem; //15px
  --spacing-lg: 1 rem; //18px
  --spacing-xl: 1.125rem; //20px
  --spacing-2xl: 1.25rem; //25px
  --spacing-3xl: 1.55rem; //30px
  --spacing-4xl: 1.875rem; //36px
  --spacing-5xl: 2.25rem; //48px
  --spacing-6xl: 3rem; //60px
  --spacing-7xl: 3.75rem; //72px
  --spacing-8xl: 4.5rem; //96px
  --spacing-9xl: 6rem; //128px

  // font-family
  --main-family: '' Codec Cold ''; //5px
}
