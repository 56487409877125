@import './fonts.scss';
@import './constants.scss';

html,
body,
#__next {
  padding: 0;
  margin: 0;
  font-family: 'Codec Cold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  font-size: var(--text-base);
  background-color: #f6f6f6;
  scroll-behavior: smooth;

  // overflow: hidden;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

li {
  list-style-type: none;
}
ul {
  margin: 0;
  padding: 0;
}
.superFancyBlockquote {
  color: #999;
  font-family: 'Hoefler Text', Georgia, serif;
  font-style: italic;
  text-align: center;
  background-color: red;
}

* {
  box-sizing: border-box;
  font-family: 'Codec Cold', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  -moz-font-feature-settings: 'kern' 1;
  -ms-font-feature-settings: 'kern' 1;
  -o-font-feature-settings: 'kern' 1;
  -webkit-font-feature-settings: 'kern' 1;
  font-feature-settings: 'kern' 1;
  font-kerning: normal;
  letter-spacing: -0.03em;
}

.WAMuiChipInput-chip-20.MuiChip-root .MuiAvatar-root {
  border-radius: 5px;
  padding: var(--spacing-3xs) var(--spacing-2xs);
  background-color: var(--lightGrey);
  color: var(--primary);
  font-family: 'Codec Cold', sans-serif;
  font-size: var(--text-xs);
  font-weight: 500;
}
// Copy of Tag component style
.MuiAutocomplete-root .MuiChip-root {
  padding: var(--spacing-3xs) var(--spacing-2xs);
  justify-content: flex-start;
  border-radius: 5px;
  font-family: 'Codec Cold', sans-serif;
  font-size: var(--text-xs);
  font-weight: 500;
  text-decoration: none;
  border: solid 1px var(--tagBlue);
  background-color: var(--tagBlue);
  color: var(--primary-dark);
  & .MuiSvgIcon-root {
    color: white;
    &:hover {
      opacity: 0.5;
    }
  }
}

.MuiAutocomplete-root .light-red .MuiChip-root {
  background-color: var(--tagRed);
  border: solid 1px var(--tagRed);
}

.MuiAutocomplete-root .grey .MuiChip-root {
  background-color: var(--tagGreen);
  border: solid 1px var(--tagGreen);
}

.MuiAutocomplete-root .rounded .MuiChip-root {
  border-radius: 5px;
}

.MuiToolbar-root {
  background-color: var(--lightGrey);
  border-radius: 4px;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar {
  background-color: transparent;
}

.MuiTableHead-root .MuiTableCell-head {
  font-weight: bold;
}

.underlinedLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

.MuiAutocomplete-option[aria-selected='true'] {
  background-color: var(--mediumGrey);
  color: var(--primary);
}
.rdw-option-wrapper,
.rdw-dropdown-wrapper {
  border-radius: 7px;
}

.rdw-option-wrapper:hover,
.rdw-dropdown-wrapper:hover {
  box-shadow: none !important;
  background-color: var(--lightGrey);
}

.rdw-dropdown-wrapper a:hover {
  background-color: var(--lightGrey);
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.uppy-StatusBar-content {
  font-size: 16px !important;
}

.uppy-StatusBar-additionalInfo {
  font-size: 14px !important;
}

.uppy-StatusBar-statusPrimary {
  line-height: 1.5 !important;
}

.uppy-StatusBar-progress {
  height: 3px;
}

.uppy-Dashboard-browse {
  padding: 8px 12px !important;
  border-radius: 5px !important;
  color: var(--white) !important;
  background-color: var(--primary) !important;
  border: 2px solid var(--primary) !important;
  font-size: var(--text-3xl) !important;
  margin-left: 5px !important;

  &:hover {
    opacity: 0.9;
  }
}
//Scroll

::-webkit-scrollbar {
  width: 8px;
  // @media (max-width: $medium) {
  //   display: none;
  // }
}

::-webkit-scrollbar-track {
  background: var(--lightGrey);
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: inherit!important;
}


.rdw-center-aligned-block > div {
  display: block!important;
}

.rdw-left-aligned-block > div {
  display: block!important;
}

.rdw-center-right-block > div {
  display: block!important;
}

.rdw-justify-aligned-block > div {
  display: block!important;
}


