.snackbar {
  p {
    font-size: var(--text-base);
  }

  svg {
    font-size: 25px;
  }

  div > svg {
    margin-top: 15px;
  }
}
