@font-face {
  font-family: 'Codec Cold';
  src: url('/assets/fonts/Codec-Cold-Thin.woff') format('woff'),
  url('/assets/fonts/Codec-Cold-Thin.ttf') format('truetype'),
  url('/assets/fonts/Codec-Cold-Thin.eot');
  font-weight: 300;
}

@font-face {
  font-family: 'Codec Cold';
  src: url('/assets/fonts/Codec-Cold-Regular.woff') format('woff'),
  url('/assets/fonts/Codec-Cold-Regular.ttf') format('truetype'), url('/assets/fonts/Codec-Cold-Regular.eot');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Codec Cold';
  src: url('/assets/fonts/Codec-Cold-Regular-Italic.woff') format('woff'),
  url('/assets/fonts/Codec-Cold-Regular-Italic.ttf') format('truetype'), url('/assets/fonts/Codec-Cold-Regular-Italic.eot');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Codec Cold';
  src: url('/assets/fonts/Codec-Cold-Bold.woff') format('woff'),
  url('/assets/fonts/Codec-Cold-Bold.ttf') format('truetype'), url('/assets/fonts/Codec-Cold-Bold.eot');
  font-weight: 700;
}

@font-face {
  font-family: 'Codec Cold';
  src: url('/assets/fonts/Codec-Cold-ExtraBold.woff') format('woff'),
  url('/assets/fonts/Codec-Cold-ExtraBold.ttf') format('truetype'), url('/assets/fonts/Codec-Cold-ExtraBold.eot');
  font-weight: 800;
}
